<template>
    <v-menu
      v-model="filterMenu" 
      offset-y 
      :nudge-bottom="5" 
      :close-on-content-click="false">
      <template #activator="{ on }">
        <v-btn
          rounded 
          v-on="on" 
          text
          class="ml-auto text-capitalize"
          color="main">
          <v-icon left>mdi-filter-outline</v-icon>
          Filters
          <v-badge
            class="ml-2 mb-2"
            color="accent"
            :content="filterCount"
            :value="filterCount > 0" />
        </v-btn>
      </template>
      <v-card width="350" class="background">
        <v-card-text>
          <div class="mt-4">
            <v-row dense v-for="(field, idx) in searchFields" :key="idx" justify="center">
              <v-col class="ma-0 pa-0" :cols="field.type === 'boolean' ? 6 : null">
                <v-switch
                  v-if="field.type === 'boolean'"
                  class="my-3"
                  dense
                  hide-details
                  :label="field.name"
                  v-model="filters[`${field.key}`]"
                  @click="updateSearchFilters">
                </v-switch>
                <v-text-field
                  v-else-if="field.type === 'date_range'"
                  style="padding: 5px"
                  class="ma-0"
                  background-color="white"
                  clearable
                  dense
                  hide-details
                  :label="field.name"
                  outlined
                  readonly
                  prepend-inner-icon="mdi-calendar"
                  :value="formatSubmittedDateRange(filters[`${field.key}`])"
                  @click:clear="clearDateRangeFilter(field.key)"
                  @click="toggleDateMenu(field.key)">
                </v-text-field>
                <v-text-field
                  v-else-if="field.type === 'text'"
                  style="padding: 5px"
                  class="ma-0"
                  v-model="filters[`${field.key}`]"
                  :label="field.name"
                  outlined
                  hide-details="auto"
                  hint="Press enter to search"
                  clearable
                  :loading="Boolean(filters[field.key]) && loading"
                  color="primary"
                  dense
                  prepend-inner-icon="mdi-magnify"
                  @click:clear="clearFilter(field.key)"
                  @change="updateSearchFilters"
                  background-color="white">
                </v-text-field>
              </v-col>
              <v-card
                  v-if="field.type === 'date_range' && dateMenu">
                  <v-date-picker
                    v-model="filters[`${field.key}`]"
                    range
                    show-current
                    no-title>
                  </v-date-picker>
                  <v-card-actions
                    class="ma-0 pa-0 mr-2"
                    style="position: absolute; right: 0; bottom: 0;"
                    >
                    <v-btn
                      text
                      @click="clearDateRangeFilter(field.key, false)">
                      Clear
                    </v-btn>
                    <v-btn
                      color="primary"
                      text
                      @click="handleChangedDate()">
                      Confirm
                    </v-btn>
                  </v-card-actions>
                </v-card>
            </v-row>
          </div>
        </v-card-text>
      </v-card>
    </v-menu>
  </template>
  <script>
  export default {
    data() {
      return {
        ordersOnly: false,
        searchFields: [
            { name: 'Order Number', key: 'product_order_number', type: 'text' },
            { name: 'Event Name', key: 'product_event_name', type: 'text' },
            { name: 'Submitted Date Range', key: 'submitted_date_range', type: 'date_range' },
            { name: 'Submitted Only', key: 'submitted', type: 'boolean' }
        ],
        filters: {},
        filterMenu: false,
        dateMenu: false,
      }
    },
    name: 'ProductOrderFilter',
    props: {
      value: Object,
      loading: Boolean
    },
    watch: {
      value: {
        handler(newValue) {
          if (newValue) {
            this.filters = { ...newValue }
          }
        },
        deep: true
      },
      filterMenu: {
        handler(newValue) {
          if (newValue && this.dateMenu) {
            if (this.filters?.submitted_date_range.length === 1) {
              this.filters.submitted_date_range = []
            }
            this.dateMenu = false
          }
        }
      }
    },
    created() {
      if (this.value) {
        this.filters = { ...this.value }
      }
    },
    computed: {
      activeFilters () {
        return Object.keys(this.filters).filter(key => {
          return Boolean(this.filters[key])
        })
      },
      filtersObj () {
        if (this.activeFilters.length > 0) {
          return this.activeFilters.reduce((obj, key) => {
            obj[key] = this.filters[key]
            return obj
          }, {})
        }
        return {}
      },
      filterCount() {
        let count = 0
        if (this.filters['product_order_number']) {
          count += 1
        }
        if (this.filters['product_event_name']) {
          count += 1
        }
        if (this.filters['submitted_date_range'].length > 1) {
          count += 1
        }
        if (this.filters['submitted']) {
          count += 1
        }
        return count
      }
    },
    methods: {
      clearFilter(key) {
        this.filters[key] = null
        this.filterMenu = false
        this.updateSearchFilters()
      },
      clearDateRangeFilter(key, update = true) {
        this.filters[key] = []
        if (update) {
          this.filterMenu = false
          this.updateSearchFilters()
        }
      },
      formatSubmittedDateRange (dateRange) {
        if (dateRange) {
          const formattedDates = dateRange.map(date => {
            const [year, month, day] = date.split('-')
            return `${month}/${day}/${year}`
          })
          return formattedDates.join(' - ')
        } else {
          return dateRange
        }
      },
      handleChangedDate () {
        this.dateMenu = false
        this.updateSearchFilters()
      },
      updateSearchFilters () {
        this.$emit('orderFilterInput', this.filtersObj)
      },
      toggleDateMenu () {
        this.dateMenu = !this.dateMenu
      }
    }
  }
  </script>
    