<template>
  <v-menu
    v-model="cartMenu"
    offset-y
    :nudge-bottom="5"
    :close-on-content-click="false">
    <template #activator="{ on }">
      <v-btn
        v-on="on"
        class="text-none"
        color="main"
        rounded
        text
        :readonly="loading"
        :disabled="orderingDisabled || !activeOrder">
        <v-icon left>mdi-cart</v-icon>
        Cart
        <v-badge
          class="ml-2 mb-2"
          color="primary" 
          :content="cartCount" 
          :value="cartCount > 0">
        </v-badge>
      </v-btn>
    </template>
    <v-card v-if="store && store.id" width="600" class="background">
      <v-container>
        <v-card-title class="my-0 pt-0 pb-2 pl-0 ml-0 heading-6">
          <v-btn 
            icon
            class="mr-1"
            :disabled="loading"
            @click.stop="cartMenu = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
          Cart - Store #{{ store.attributes.WHOLESALER_STORE_NUMBER }}
          <template v-if="productEvent && productEvent.name">
            <v-spacer></v-spacer>
            {{ productEvent.name }}
          </template>
        </v-card-title>
        <v-divider class="pb-0 mb-0" />
        <template v-if="loading">
          <v-progress-linear indeterminate color="primary" height="6" />
        </template>
        <template v-else>
          <v-list 
            v-if="orderItems.length > 0" 
            dense 
            style="max-height: 60vh; overflow-y: auto;">
            <v-list-item
              class="ma-0 pl-2"
              v-for="item in orderItems"
              :key="item.id"
              :value="item">
              <v-row dense align="center" class="pl-0 ml-0">
                <v-col cols="auto" class="ml-0 pl-0 mr-2">
                  <v-btn x-small icon @click="removeItem(item)">
                    <v-icon dense>mdi-close</v-icon>
                  </v-btn>
                </v-col>

              <v-col align="start" class="pl-0 ml-0">
                #{{ item.item_id + ' - ' }} {{ item.description }}
              </v-col>

              <v-col cols="2">
                ${{ $config.formatCurrency(item.cost) }}
              </v-col>
              <v-col cols="auto" class="pl-0">
                <div class="qty-controls">
                <v-btn
                  icon
                  x-small
                  class="my-auto"
                  fab
                  dense
                  color="primary"
                  :disabled="loading || item.quantity === 0"
                  @click="decreaseQty(item)">
                  <v-icon>mdi-minus</v-icon>
                </v-btn>
                <v-text-field
                  v-model="item.quantity"
                  type="number"
                  min="1"
                  single-line
                  :disabled="loading"
                  @input="handleInputValue(item)">
                </v-text-field>
                <v-btn 
                  icon 
                  x-small
                  class="my-auto"
                  fab
                  dense
                  :disabled="loading"
                  color="primary"
                  @click="increaseQty(item)">
                  <v-icon>mdi-plus</v-icon>
                </v-btn>
                </div>
              </v-col>
              </v-row>
            </v-list-item>
          </v-list>
          <v-row class="my-4" v-else dense>
          <v-col align="center">
            <p class="font-weight-light text-no-wrap">
              Your product order is empty.
            </p>
          </v-col>
        </v-row>
        <div style="position: relative">
          <v-divider></v-divider>
        <v-row dense align="center" class="pt-2">
          <v-col align="center">
            <p class="font-weight-medium text-no-wrap my-auto">
              Total: ${{ totalCost }}
            </p>
          </v-col>
          <v-col align="center">
            <v-btn :disabled="loading || cartCount === 0" class="mr-2" color="accent" @click="submitCart">
              Submit
            </v-btn>
            <v-btn class="mx-2" outlined color="accent" @click="clearCart">
              Clear
            </v-btn>
          </v-col>
        </v-row>
        </div>
        </template>
      </v-container>
    </v-card>
    <SubmitOrderDialog
      v-if="submitDialog"
      v-model="submitDialog"
      :submitting="submitting"
      @cancel="closeDialogs" 
      @confirmSubmit="submitOrder"/>
    <ClearOrderDialog
      v-if="clearDialog"
      v-model="clearDialog" 
      :clearing="clearing"
      @cancel="closeDialogs" 
      @confirmClear="clearOrder"/>
  </v-menu>
</template>
<script>
// api
import ProductOrder from '@/api/product-order'
// mixins
import { displayAlert } from '@/mixins/alert'
import { utils } from '@/mixins/utils'
// components
const SubmitOrderDialog = () => import('@/components/products/SubmitOrderDialog')
const ClearOrderDialog = () => import('@/components/products/ClearOrderDialog')
import { debounce } from 'lodash'
export default {
  data() {
    return {
      submitting: false,
      clearing: false,
      submitDialog: false,
      clearDialog: false,
      cartMenu: false,
    }
  },
  name: 'ProductCart',
  mixins: [utils, displayAlert],
  components: { SubmitOrderDialog, ClearOrderDialog },
  props: {
    value: Boolean,
    loading: Boolean,
    orderItems: Array,
    order: Object,
    store: Object,
    productEvent: Object,
    orderingDisabled: Boolean,
    activeOrder: Boolean
  },
  computed: {
    cartCount() {
      return this.orderItems?.length || 0
    },
    isCartEmpty() {
      return this.cartCount === 0
    },
    totalCost() {
      let finalTotal = 0
      if (this.orderItems?.length > 0) {
        finalTotal = this.orderItems.reduce((total, item) => {
          return total + (item.cost * item.quantity)
        }, 0)
      }
      return this.$config.formatCurrency(finalTotal)
    }
  },
  methods: {
    qtyChange(item) {
      if(item.quantity < 0) {
        item.quantity = 0
      }
      this.$emit('orderItemUpdate', item)
    },
    increaseQty(item) {
      item.quantity += 1;
      this.$emit('orderItemUpdate', item)
    },
    decreaseQty(item) {
      item.quantity -= 1;
      this.$emit('orderItemUpdate', item)
    },
    removeItem(item) {
      this.$emit('removeItem', item)
    },
    handleInputValue: debounce(function (item) {
      if (!item.quantity) item.quantity = 0
      this.qtyChange(item)
    }, 400),
    closeDialogs() {
      this.submitDialog = false
      this.clearDialog = false
    },
    submitCart() {
      this.submitDialog = true
    },
    async submitOrder() {
      this.submitting = true
      const eventId = (this.productEvent.id === 'open-stock')
        ? null
        : this.productEvent.id

      try {
        await ProductOrder.updateProductOrder(this.order.id, this.order.party_id, eventId, this.order.product_order_type_id, true)
        this.submitDialog = false
        this.cartMenu = false
        this.$emit('orderSubmitted')
      } catch (e) {
        this.handleError(e)
      } finally {
        this.submitting = false
      }
    },
    clearCart() {
      this.clearDialog = true
    },
    confirmClear(){
      this.clearDialog = false
      this.cartMenu = false
      this.$emit('clearOrder')
    },
    async clearOrder() {
      this.clearing = true
      try {
        const promises = this.orderItems.map((productOrderItem) => {
          return ProductOrder.deleteProductOrderItem(productOrderItem.id)
        })
        const { rejected } = await this.getAllSettled(promises)
        if (rejected?.length > 0) throw rejected

        await ProductOrder.deleteProductOrder(this.order.id)
        this.clearDialog = false
        this.cartMenu = false
        this.$emit('orderCleared')
      } catch (e) {
        this.handleError(e)
      } finally {
        this.clearing = false
      }
    }
  }
}
</script>
<style scoped>
.qty-controls {
  display: grid; 
  width: 100%; 
  max-width: 100px; 
  grid-template-columns: auto 45px auto; 
  justify-content: flex-end;
}
</style>
  