import Vue from 'vue'
import Vuex from 'vuex'

import Party from '@/api/party'
import PartyRelationship from '@/api/party-relationship'
import ProductOrder from '@/api/product-order'
import Item from '@/api/item'
import { getInstance } from '@/auth/auth0-plugin'

// Modules
import sources from './modules/source'

Vue.use(Vuex)

let itemSourceCache = {}
const namespaced = true
const modules = { sources }
const strict = false

const state = {
  userRelatedParties: [],
  productOrderTypes: [],
  stores: []
}

const mutations = {
  setUserRelatedParties (state, payload) {
    state.userRelatedParties = payload
  },
  setProductOrderTypes (state, payload) {
    state.productOrderTypes = payload
  },
  setStores (state, payload) {
    state.stores = payload
  }
}

const actions = {
  async getUserRelatedParties (context) {
    const userParties = context.getters.userRelatedParties
    if (userParties.length > 0) return

    const authService = getInstance()
    let relatedParties = []
    const res = await Party.getUserProfile(authService.user.email)
      if (res?.data?.length > 0) {
        relatedParties = res.data
      }
    return context.commit('setUserRelatedParties', relatedParties)
  },
  async getProductOrderTypes(context) {
    const productOrderTypes = context.getters.productOrderTypes
    if (productOrderTypes.length > 0) return
    let values = []
    const res = await ProductOrder.getProductOrderTypes();
      if (res?.data?.length > 0) {
        values = res.data
      }
    return context.commit('setProductOrderTypes', values)
  },
  async getStores(context) {
    if (context.getters.stores.length > 0) return
    let stores = []
    const { data } = await Party.searchParties({
      party_types: ['STORE']
    })
    if (data?.length > 0) {
      const filteredStores = data.flatMap(store => {
        if (store.attributes?.PRODUCT_CATALOG_STORE === 'True') {
          return formatStore(store)
        }
        return []
      })
      stores = await setPartyItemSources(filteredStores)
    }
    return context.commit('setStores', stores)
  }
}

const getters = {
  userRelatedParties (state) {
    return state.userRelatedParties
  },
  productOrderTypes (state) {
    return state.productOrderTypes
  },
  stores (state) {
    return state.stores
  }
}

async function setPartyItemSources(stores) {
  if (stores.length === 0) return []
    let mapped = []
    const partyIds = stores.map(s => s.id)
    const res = await getUpstreamPartyRels(partyIds)
    if (res?.data?.length > 0) {
      const upstreamRels = res.data.filter(r => {
        return (partyIds.includes(r.from_party_id))
          && ['SUPPLIED_BY', 'DSD_SUPPLIED_BY'].includes(r.from_rel_type)
      })
      const upstreamIds = [
        ...new Set(upstreamRels.map(result => result.to_party_id))
      ]
      const sourceRels = await getUpstreamSourceRels(upstreamIds)
      if (sourceRels.length > 0) {
        mapped = stores.map(store => {
          const party_id = store.id
          const dcRel = upstreamRels.find(r => {
            return (r.from_party_id === party_id) && (r.from_rel_type === 'SUPPLIED_BY')
          })
          const dsdRel = upstreamRels.find(r => {
            return (r.from_party_id === party_id) && (r.from_rel_type === 'DSD_SUPPLIED_BY')
          })
          const dcSourceRel = sourceRels.find(result => dcRel?.to_party_id === result.party_id)
          const dsdSourceRel = sourceRels.find(result => dsdRel?.to_party_id === result.party_id)
          
          const dsd_source = dsdSourceRel?.source_id || null
          let dc_source = dcSourceRel?.source_id || null
          // TO DO: allow passing multiple sources so we don't need to hardcode this. temp fix needed for source used in seasonal product load.
          if (dc_source === 'hawaii') dc_source = 'tracy'
          return { ...store, dc_source, dsd_source }
        })
      }
    }
    return mapped
}

async function getUpstreamPartyRels(partyIds) {
  if (partyIds.length === 0) return []
  const payload = {
    from_party_ids: partyIds,
    excluded_upstream_types: ['WHOLESALER', 'REGION_GROUP', 'AD_GROUP', 'OWNER']
  }
  return PartyRelationship.getUpstreamRelationships(payload)
}

async function getUpstreamSourceRels(upstreamIds) {
  const promises = upstreamIds.map(id => {
    const sourceRels = itemSourceCache[id]
    return (
      sourceRels ||
      Item.getSourceRelsByPartyId(id).then(res => {
        if (res?.data) {
          itemSourceCache[id] = res.data
        }
        return res.data
      })
    )
  })
  const rejected = []
  const results = await Promise.allSettled(promises)
  const fulfilled = results.flatMap(result => {
    if (result.status === 'rejected') {
      rejected.push(result)
      return []
    }
    return result.value
  })
  if (rejected.length > 0) throw rejected
  return fulfilled
}

function formatStore(party) {
  let display_name = party.party_name || party.name
  if (party.attributes) {
    let attrVal = party.attributes['WHOLESALER_STORE_NUMBER'] || ''
    if (attrVal) {
      attrVal = `#${attrVal}`
    }
    display_name = `${attrVal} ${display_name}`
  }
  party.name = display_name

  // build store price_key string
  // needed to display correct unit & case cost values from product price_data object
  let price_key = null
  const markup = party.attributes?.STORE_PRICE_MARKUP
  const price_list = party.attributes?.STORE_PRICE_LIST

  if (markup && price_list) {
    const price_list_prefix = price_list.split('-')[0] // e.g. SEA from SEA-PMC
    price_key = markup.startsWith('JBG')
      ? markup.replaceAll('-', '_').toLowerCase() // e.g. jbg_hibase
      : `${price_list_prefix.toLowerCase()}_${markup.toLowerCase()}` // e.g. sea_mkt
  }
  
  party.price_key = price_key
  return party
}

export default new Vuex.Store({
  state,
  strict,
  actions,
  modules,
  getters,
  mutations,
  namespaced
})
