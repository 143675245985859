<template>
  <v-container fluid class="ma-0 pa-0">
    <v-toolbar flat class="grey lighten-3">
      <v-icon class="mr-2">mdi-package-variant-closed</v-icon>
      <v-toolbar-title>Order History</v-toolbar-title>
      <v-divider class="mx-4" inset vertical></v-divider>
      <v-col cols="3" style="max-width: 430px">
        <v-autocomplete
          v-model="storeId"
          outlined
          dense
          :items="stores"
          :loading="loading"
          @change="getOrders"
          item-text="name"
          item-value="id"
          hide-details
          :clearable="isAdmin"
          background-color="white"
          label="Store"
          prepend-inner-icon="mdi-store-outline">
        </v-autocomplete>
      </v-col>
      <ProductOrderFilter
        v-model="searchParams"
        :loading="loading"
        @orderFilterInput="handleFilterInput">
      </ProductOrderFilter>
      <v-spacer />
      <v-btn
        v-if="isAdmin"
        @click.stop="eventOrderExportModal = true"
        color="primary"
        class="mr-4 white--text">
        Export Summary 
        <v-icon right>mdi-download</v-icon>
      </v-btn>
      <v-tooltip top >
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            class="mr-4"
            to="/products"
            v-bind="attrs"
            v-on="on"
            small
            fab
            color="main"
            rounded
            text
            >
            <v-icon>mdi-cart</v-icon>
          </v-btn>
        </template>
        <span>Back to Products</span>
      </v-tooltip>
      
</v-toolbar>
    <v-data-table
      :height="tableSize"
      :headers="headers"
      :items.sync="productOrders"
      :loading="loading"
      loading-text="Loading... Please wait"
      :no-data-text="searchParams.submitted ? 'No submitted orders to show' : 'No orders to show'"
      hide-default-footer
      :items-per-page.sync="pageSize"
      :page.sync="page"
      item-key="id"
      fixed-header
      dense>

      <template v-slot:[`item.product_order_number`]="{ item }">
        <span class="text-no-wrap">
          {{ item.product_order_number || '-' }}
        </span>
      </template>

      <template v-slot:[`item.submitted`]="{ item }">
        <v-chip small dark
          :color="getStatusColor(item.submitted)">
          <span style="display: inline-block; margin: auto; width: 72px; text-align: center">
            {{ getSubmittedText(item.submitted) }}
          </span>
        </v-chip>
      </template>

      <template v-slot:[`item.audit.created_on`]="{ item }">
        <span class="noWrap">
          {{ $config.formatTimestamp(item.audit.created_on) }}
        </span>
      </template>
      
      <template v-slot:[`item.submitted_on`]="{ item }">
        <span class="noWrap">
          {{ item.submitted_on ? $config.formatTimestamp(item.submitted_on) : '-' }}
        </span>
      </template>

      <template v-slot:[`item.order_items`]="{ item }">
        <v-btn
          icon
          @click="showOrderView(item)">
          <v-icon>mdi-view-list</v-icon>
        </v-btn>
      </template>

      <template v-slot:footer>
          <PagingFooter
            :pageSizes="pageSizes"
            :pageSize.sync="pageSize"
            :page="page"
            :disablePrevious="disablePrevious"
            :disableNext="disableNext" 
            @previous="previous" 
            @next="next">
          </PagingFooter>
        </template>
    </v-data-table>

    <v-card class="mx-auto" max-width="300">
      <OrderView 
        v-if="orderModal"
        v-model="orderModal"
        :selectedProductOrder="selectedProductOrder"
        :storePriceKey="storePriceKey"
        @closeOrderView="closeOrderView"
      />
    </v-card>
    <EventOrderExportView
      v-if="eventOrderExportModal"
      v-model="eventOrderExportModal"
      @closeEventOrderExportView="eventOrderExportModal = false"/>
  </v-container>
</template>
<script>
// api
import ProductOrder from '@/api/product-order'
// mixins
import { fullWidthTable } from '@/mixins/table'
import { displayAlert } from '@/mixins/alert'
import { userAccess } from '@/mixins/user-access'
import { utils } from '@/mixins/utils'
// components
import PagingFooter from '@/components/PagingFooter.vue'
import OrderView from '@/components/product-orders/OrderView.vue'
import EventOrderExportView from '@/components/product-events/EventOrderExportView.vue'
import ProductOrderFilter from '@/components/product-orders/ProductOrderFilter'

export default {
  name: 'OrdersTable',
  data() {
    return {
      loading: false,
      storeId: null,
      productOrders: [],
      selectedProductOrder: {},
      searchParams: {
        submitted: true,
        submitted_date_range: []
      },
      orderModal: false,
      eventOrderExportModal: false,
      headerProps: {
        sortable: true,
        filterable: false,
        class: 'accent white--text text-no-wrap'
      },
      baseHeaders: [
        { sortable: false },
        { text: 'Order #', value: 'product_order_number', sortable: true },
        { text: 'Store', value: 'store_name', sortable: true },
        { text: 'Event', value: 'product_event_name', sortable: true },
        { text: 'Status', value: 'submitted', sortable: true, align: 'center' },
        { text: 'Created By', value: 'audit.created_by', sortable: true },
        { text: 'Created On', value: 'audit.created_on', sortable: true },
        { text: 'Submitted On', value: 'submitted_on', sortable: true },
        { text: 'Items', value: 'order_items', sortable: false }
      ],
      formatFields: [
        'cost', 'each_cost', 'sale_price'
      ],
      page: 1,
      pageSize: 50,
      pageSizes: [25, 50, 100],
    }
  },
  mixins: [fullWidthTable, displayAlert, userAccess, utils],
  components: { PagingFooter, OrderView, EventOrderExportView, ProductOrderFilter},
  watch: {
    page: {
      handler(newValue) {
        if (newValue) this.getOrders()
      }
    },
    pageSize: {
      handler() {
        if (this.page > 1) {
          this.page = 1
        } else {
          this.getOrders()
        }
      }
    },
    storeId(newValue) {
      if (newValue) {
        sessionStorage.setItem('store_id', newValue)
      }
    }
  },
  async mounted() {
    this.loading = true
    this.nonTableHeight = 220
    await Promise.all([
        this.$store.dispatch('getUserRelatedParties'),
        this.$store.dispatch('getStores')
    ]).catch(err => {
      this.loading = false
      this.handleError(err)
    })
    if (this.stores.length > 0) {
      if (sessionStorage.store_id) {
        this.storeId = sessionStorage.store_id
      } else {
        this.storeId = this.stores[0].id
      }
      await this.getOrders()
    } else {
      this.loading = false
    }
  },
  computed: {
    stores() {
      if (this.userStores.length > 0) {
        return this.sortByKey(this.userStores, 'name')
      }
      return []
    },
    selectedStore() {
      return this.stores.find(s => s?.id === this.storeId)
    },
    storePriceKey() {
      return this.selectedStore?.price_key || null
    },
    headers() {
      return this.baseHeaders.map(header => {
        return { ...this.headerProps, ...header }
      })
    },
    limit() {
      return this.pageSize
    },
    offset() {
      return (this.page - 1) * this.pageSize
    },
    disablePrevious() {
      return this.page === 1
    },
    disableNext() {
      return this.productOrders.length < this.pageSize
    }
  },
  methods: {
    async getOrders() {
      this.loading = true
      let params = {
        offset: this.offset,
        limit: this.limit,
        ...this.searchParams
      }
      if (this.storeId) {
        params = {
          ...params,
          party_id: this.storeId
        }
      }
      if (params?.submitted_date_range.length < 2) {
        delete params.submitted_date_range
      }
      try {
        let orders = []
        const { data } = await ProductOrder.getProductOrders(params)
        if (data?.length > 0) {
          orders = data.map(order => this.formatOrder(order))
        }
        this.productOrders = orders
      } catch (e) {
        this.handleError(e)
      } finally {
        this.loading = false
      }
    },
    handleFilterInput(newValue) {
      this.searchParams = newValue
      return (this.page > 1) ? this.page = 1 : this.getOrders()
    },
    formatOrder(order) {
      const store = this.stores.find(s => s?.id === order.party_id)
      order.store_name = store.name
      if (!order?.product_event_id) {
        order.product_event_name = 'Open Stock'
      }
      return order
    },
    showOrderView(order) {
      this.orderModal = true
      this.selectedProductOrder = order
    },
    closeOrderView() {
      this.orderModal = false
    },
    getStatusColor (submitted) {
      return submitted ? 'green' : 'orange lighten-1'
    },
    getSubmittedText (submitted) {
      return submitted ? 'SUBMITTED' : 'IN PROGRESS'
    }
  }
}
</script>
      