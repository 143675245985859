import axios from 'axios'
import AuthToken from '@/auth/auth-token'

class Products {
  constructor () {
    const base = axios.create({
      // using base items api
      baseURL: process.env.VUE_APP_BASE_API,
      withCredentials: false,
      headers: {
        'Content-type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Headers': 'Origin, X-Requested-With, Content-Type, Accept'
      }
    })
    this.products = base
  }

  async getProducts (params = {}) {
    await AuthToken.setAuth(this.products)
    if (params?.product_event_id) {
      return this.getEventProducts(params)
    }
    return this.products({
      url: `/products`,
      method: 'GET',
      params: { 
        offset: params.offset || 0, 
        limit: params.limit || 50,
        ...params
      }
    })
  }

  async getEventProducts (params) {
    const { product_event_id, ...rest } = params
    return this.products({
      url: `/products/event/${product_event_id}`,
      method: 'GET',
      params: { ...rest }
    })
  }

  async bulkSearch (payload, offset = 0, limit = 200) {
    await AuthToken.setAuth(this.products)
    return this.products({
      url: `/products/bulk_search`,
      method: 'POST',
      data: payload,
      params: { offset, limit }
    })
  }

  async getProduct (id) {
    await AuthToken.setAuth(this.products, {
      audience: process.env.VUE_APP_API_AUDIENCE
    })
    return this.products({
      url: `/product/${id}`,
      method: 'GET'
    })
  }

  async createProduct (product) {
    await AuthToken.setAuth(this.products, {
      audience: process.env.VUE_APP_API_AUDIENCE
    })
    return this.products({
      url: `/product`,
      method: 'POST',
      data: product
    })
  }

  async updateProduct (product) {
    await AuthToken.setAuth(this.products, {
      audience: process.env.VUE_APP_API_AUDIENCE
    })
    return this.products({
      url: `/product/${product.id}`,
      method: 'PUT',
      data: product
    })
  }

  async deleteProduct (id) {
    await AuthToken.setAuth(this.products, {
      audience: process.env.VUE_APP_API_AUDIENCE
    })
    return this.products({
      url: `/product/${id}`,
      method: 'DELETE'
    })
  }

  async uploadProductImage (data) {
    await AuthToken.setAuth(this.products)
    return this.products({
      url: '/product_image',
      headers: {
        'Content-Type': 'multipart/form-data'
      },
      method: 'POST',
      data
    })
  }

  async deleteProductImage (upc) {
    await AuthToken.setAuth(this.products)
    return this.products({
      url: `/product_image/${upc}`,
      method: 'DELETE'
    })
  }

  async uploadProductImages (data) {
    await AuthToken.setAuth(this.products)
    return this.products({
      url: '/product_images',
      headers: {
        'Content-Type': 'multipart/form-data'
      },
      method: 'POST',
      data
    })
  }
  
  async getValidUPCs (upcs) {
    await AuthToken.setAuth(this.products)
    return await this.products({
      url: '/products/validate_upcs',
      method: 'POST',
      data: {'values': upcs, 'item_id_search': false, 'upc_search': true}
    })
  }

  async getDistinctCategoriesAndSubcategories() {
    await AuthToken.setAuth(this.products)
    return await this.products({
      url:'/products/distinct_categories_and_subcategories',
      method: 'GET'
    })
  }
}

export default new Products()