<template>
    <v-overlay v-if="$auth.isLoading || !$auth.isAuthenticated">
    <v-progress-circular
      indeterminate
      size="64">
    </v-progress-circular>
  </v-overlay>

  <v-app v-else>
    <v-app-bar app color="main" dark clipped-left>
      <v-app-bar-nav-icon
        v-show="$auth.isAuthenticated"
        @click.stop="drawer = !drawer"
        class="mx-auto" />
          <router-link :to="{ path: '/'}">
          <v-app-bar-nav-icon>
            <v-icon color="#fff">mdi-home</v-icon>
          </v-app-bar-nav-icon>
          </router-link>
      <v-spacer/>
      <v-col cols="auto" class="pr-0">
        <PortalNavMenu active="product-catalog" />
      </v-col>
    </v-app-bar>
    <v-navigation-drawer
      v-model="drawer"
      style="z-index: 20"
      clipped
      color="grey lighten-3"
      width="200"
      app>
      <v-list nav>
        <v-list-item :to="{ name: 'products' }" link>
          <v-list-item-action>
            <v-icon>mdi-cart</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>Products</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item :to="{ name: 'orders' }" link>
          <v-list-item-action>
            <v-icon>mdi-history</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>Order History</v-list-item-title>
          </v-list-item-content> 
        </v-list-item>
        <v-list-item v-if="isAdmin" :to="{ name: 'admin' }" link>
          <v-list-item-action>
            <v-icon>mdi-cog-outline</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>Admin</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
      <template v-slot:append>
        <v-divider></v-divider>
        <div :class="[(!miniVariant) ? 'pa-2' : 'pa-1']">
          <v-btn
            block 
            id="logoutBtn" 
            :style="styleObject" 
            @click.prevent="logout()">
            <v-icon color="#fff">mdi-logout</v-icon>
            <span v-if="!miniVariant">Logout</span>
          </v-btn>
        </div>
      </template>
    </v-navigation-drawer>

    <v-main>
      <AlertBar />
      <router-view v-if="hasPortalAccess" />
      <v-alert
        v-else
        type="error"
        text
        border="left">
        You don't have authorized permissions to access this site.
      </v-alert>
    </v-main>
    <v-footer color="main" app class="justify-space-between">
      <span class="white--text">iPro <span class="registeredTrademark">&reg;</span> Systems, LLC. &copy; {{ new Date().getFullYear() }}</span>
      <span class="white--text">v. {{version}}</span>
    </v-footer>
  </v-app>
</template>
<script>
import { userAccess } from '@/mixins/user-access'
const PortalNavMenu = () => import('@/components/shared/PortalNavMenu.vue')
import AlertBar from '@/components/shared/AlertBar.vue'
export default {
  name: 'App',
  mixins: [userAccess],
  components: { AlertBar, PortalNavMenu },
  data: () => ({
    drawer: false,
    miniVariant: false,
    styleObject: {
      background: '#eb6565',
      color: 'white',
      fontSize: '15px'
    },
    storedKeys: ['store_id', 'event_id', 'search_params', 'product_search_filters']
  }),
  computed: {
    version () {
      return process.env.VUE_APP_VERSION
    }
  },
  methods: {
    logout() {
      for (const key of this.storedKeys) {
        sessionStorage.removeItem(key)
      }
      this.$auth.logout({
        returnTo: window.location.origin
      })
    }
  }
}
</script>
<style>
.v-text-field__slot input::-webkit-calendar-picker-indicator {
  display: none;
}

.v-text-field__slot input[type="date"] {
  cursor: text;
}

.v-text-field__slot input[type="date"]::-webkit-input-placeholder {
  visibility: hidden;
}

.theme--light.v-data-table .v-data-table-header th.sortable .v-data-table-header__icon {
  color: #fff !important;
}

span.registeredTrademark {
  position: relative;
  top: -.3rem;
  font-size: .5rem;
  left: -0.05rem;
}

footer {
  font-size: small;
}
</style>
