<template>
  <v-menu
    v-model="filterMenu" 
    offset-y 
    :nudge-bottom="5" 
    :close-on-content-click="false">
    <template #activator="{ on }">
      <v-btn
        rounded 
        v-on="on" 
        text
        class="ml-auto text-capitalize"
        color="main">
        <v-icon left>mdi-filter-outline</v-icon>
        Filters
        <v-badge
          class="ml-2 mb-2"
          color="accent"
          :content="filterCount"
          :value="filterCount > 0" />
      </v-btn>
    </template>
    <v-card width="350" class="background">
      <v-card-text>
        <v-col class="px-0" style="max-width: 430px" v-if="productGroups.length > 1">
            <v-autocomplete
              v-model="filters['product_group_id']"
              dense
              outlined
              :items="productGroups"
              item-text="name"
              item-value="id"
              hide-details
              label="Event Product Group"
              background-color="white"
              prepend-inner-icon="mdi-filter-outline"
              @change="handleChangedGroup"
              clearable>
            </v-autocomplete>
          </v-col>
        <v-row dense v-for="(field, idx) in searchFields" :key="idx" justify="center">
          <v-col class="ma-0 pa-0" :cols="field.type === 'boolean' || field.key === 'active_orders'
            ? 8
            : null
            ">
            <v-autocomplete
              v-if="field.type === 'select'"
              outlined
              clearable
              dense
              :items="departments"
              background-color="white"
              :label="field.name"
              color="primary"
              :menu-props="{ maxHeight: 215 }"
              v-model="filters[`${field.key}`]">
            </v-autocomplete>
            <v-switch
              v-else-if="field.type === 'boolean'"
              inset
              class="mt-0"
              :label="field.name"
              v-model="filters[`${field.key}`]">
            </v-switch>
            <v-text-field
              v-else
              style="padding: 5px"
              v-model="filters[`${field.key}`]"
              :label="field.name"
              outlined
              hide-details="auto"
              hint="Press enter to search"
              clearable
              :loading="Boolean(filters[field.key]) && loading"
              color="primary"
              dense
              prepend-inner-icon="mdi-magnify"
              @click:clear="clearFilter(field.key)"
              @keydown.enter="updateSearchFilters"
              background-color="white">
            </v-text-field>
          </v-col>
        </v-row>
        <v-row dense>
          <v-col v-if="openStockSelected" class="ma-0 pa-0" style="max-width: 430px">
            <v-autocomplete
              outlined
              clearable
              dense
              hide-details
              style="padding: 5px"
              :items="productCategories"
              background-color="white"
              label="Product Group"
              color="primary"
              :menu-props="{ maxHeight: 215 }"
              v-model="filters[`category`]"
              prepend-inner-icon="mdi-filter-outline"
              @change="handleChangedGroup">
            </v-autocomplete>
          </v-col>
        </v-row>
        <v-row dense>
          <v-col v-if="openStockSelected" class="ma-0 pa-0" style="max-width: 430px">
            <v-autocomplete
              outlined
              clearable
              dense
              hide-details
              style="padding: 5px"
              :items="filteredSubcategories"
              background-color="white"
              label="Product Category"
              color="primary"
              :menu-props="{ maxHeight: 215 }"
              v-model="filters[`subcategory`]"
              prepend-inner-icon="mdi-filter-outline"
              @change="handleChangedGroup">
            </v-autocomplete>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </v-menu>
</template>
<script>
// api
import Products from '@/api/product'

export default {
  data() {
    return {
      ordersOnly: false,
      searchFields: [
        { name: 'Item Description', key: 'description', type: 'text' },
        { name: 'Item Id', key: 'item_id', type: 'text' },
        { name: 'UPC', key: 'upc', type: 'text' },
      ],
      filters: {},
      filterMenu: false,
      productCategories: [],
      productSubcategories: []
    }
  },
  name: 'ProductFilter',
  props: {
    value: Object,
    loading: Boolean,
    productGroups: Array,
    openStockSelected: Boolean
  },
  watch: {
    value: {
      handler(newValue) {
        if (newValue) {
          this.filters = { ...newValue }
        }
      },
      deep: true
    }
  },
  async created() {
    if (this.value) {
      this.filters = { ...this.value }
    }

    await this.getProductCategoriesAndSubcategories()
  },
  computed: {
    activeFilters () {
      return Object.keys(this.filters).filter(key => {
        return Boolean(this.filters[key])
      })
    },
    filtersObj () {
      if (this.activeFilters.length > 0) {
        return this.activeFilters.reduce((obj, key) => {
          obj[key] = this.filters[key]
          return obj
        }, {})
      }
      return {}
    },
    filterCount() {
      return Object.keys(this.value).length || 0
    },
    filteredSubcategories() {
      if (this.filters['category']) {
        return this.productSubcategories
          .filter(subcategory => subcategory['category'] === this.filters['category'])
          .map(subcategory => subcategory['subcategory'])
      }
      else {
        return this.productSubcategories.map(subcategory => subcategory['subcategory'])
      }
    }
  },
  methods: {
    handleChangedGroup(newValue) {
      this.filterMenu = false

      // Clear subcategory if category changes
      if (newValue === this.filters.category) {
        this.filters.subcategory = undefined
      } else if (newValue && newValue === this.filters.subcategory) {
        let selectedSubcategory = this.productSubcategories.find((item) => item.subcategory === newValue)
        this.filters.category = selectedSubcategory.category
      }
      this.updateSearchFilters()
    },
    clearFilter(key) {
      this.filters[key] = null
      this.filterMenu = false
      this.updateSearchFilters()
    },
    updateSearchFilters () {
      this.$emit('filterInput', this.filtersObj)
      if (this.activeFilters.length > 0) {
        sessionStorage.setItem('search_params', JSON.stringify(this.filtersObj))
      } else {
        sessionStorage.removeItem('search_params')
      }
    },
    async getProductCategoriesAndSubcategories () {
      if (sessionStorage.productCategories && sessionStorage.productSubcategories) {
        this.productCategories = JSON.parse(sessionStorage.productCategories)
        this.productSubcategories = JSON.parse(sessionStorage.productSubcategories)
      }
      else {
        const { data } = await Products.getDistinctCategoriesAndSubcategories()
        const uniqueCategories = data.reduce((set, data) => {
          set.add(data['category'])
          return set
        }, new Set())
        
        this.productCategories = [...uniqueCategories]
        this.productSubcategories = data

        sessionStorage.setItem('productCategories', JSON.stringify(this.productCategories))
        sessionStorage.setItem('productSubcategories', JSON.stringify(this.productSubcategories))
      }
    }
  }
}
</script>
  